import type { RouteLocationNormalized } from 'vue-router';
import { handleDataPreload } from './utils/handleDataPreload';
import { handleProjectNumber, isLoggedIn } from '~/router/utils';

export const preloadRequiredData = async () => {
  const { profile } = useProfileStore();
  const { projects } = useProjectStore();

  const promises = [];

  if (!profile.value?.cn) {
    const { getProfile, getAccountExtensionRequests } = useProfileStore();

    promises.push(getProfile(), getAccountExtensionRequests());
  }

  if (!projects.value?.length) {
    const { getProjects } = useProjectStore();

    promises.push(getProjects());
  }

  await Promise.all(promises);
};

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized) => {
    if (!to.meta.requiresAuth) {
      await handleDataPreload(to);

      return true;
    }

    const { success } = await isLoggedIn();

    if (success) {
      await handleDataPreload(to);

      handleProjectNumber(to);

      await preloadRequiredData();

      const { hasRequiredRoles, hasAnyRequiredRole } = useProfileStore();

      if (to?.meta?.requiredRoles && !hasRequiredRoles(to.meta.requiredRoles)) {
        return navigateTo({ name: 'Dashboard' });
      }

      if (
        to?.meta?.anyRequiredRoles &&
        !hasAnyRequiredRole(to.meta.anyRequiredRoles)
      ) {
        return navigateTo({ name: 'Dashboard' });
      }

      return;
    }

    return navigateTo({ name: 'Login', query: { redirect: to.fullPath } });
  },
);
